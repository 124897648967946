import {Link} from "react-router-dom";
import "../edit/Blabber.css"
function Blabber() {
    return(
        <div className="section3" id="section3">
            <h2 className="projectsH2">BLABBER</h2>
            <p className="appP">
              
                <h3>Blabber</h3>
                <p>MYRA’s first project is BLABBER. Blabber is a Blockchain-based and decentralized social media application. With Blabber application, you are very close to the world of cryptocurrency. Sign up for the app and start reading Blabs. Earn and have fun here! Along with the social media application Blabber, users use MYRA Token by sharing Blabber and advertising. As they use the application, our users can earn money by fulfilling certain conditions.
                    Users can register to the app, edit their profile, view Blabs, like and dislike Blabs. Users must have 1750 MYRA in their account to be able to Blab, like and dislike, no fee is required. *MYRA quantity will be updated by adapting to 1 USDT.
                </p>
                <h4>What Is Blab</h4>
                <p >Blab is what users can share their thoughts, photos, videos and various things in the Blabber app. Users can comment on shared Blabs in the Blabber app. Users must have a Blue Tick or Golden Tick to comment. Users can open tags and share Blabber in the Blabber application. Users must have a Blue Tick or Golden Tick to open a tag. Users with Blue Tick and Golden Tick can advertise within the application. They need to verify their profile and account.</p>
                <h4>Use of MYRA Token in Blabber Application</h4>
                <p >
                    Individual users and cryptocurrencies can register. Users and cryptocurrencies can edit their profile, share Blab, advertise, tag, comment, follow other users, like and dislike Blabs. *In some cases users and cryptocurrencies are required to purchase Blue and Golden Ticks.

                    There are individual users "Blue Tick" within the application. The user pays 175,000 MYRA(100 USDT) per year to get the blue tick. *MYRA quantity will be updated by adapting to 100 USDT. Thanks to the blue tick, users can comment on Blabs and send messages to other users.
                    There is a "Golden Tick" for Crypto Coins in the application. Cryptocurrency pays 525,000 MYRA(300 USDT) per year to get golden tick. *MYRA quantity will be updated by adapting to 300 USDT.
                    Users must pay 2.500.000 MYRA(1500 USDT, for 1 day), 3.750.000 MYRA (2250 USDT, for 2 days) and 5.000.000 MYRA (3000 USDT, for 3 days) to open a tag. *MYRA quantity will be updated by adapting to 1500 USDT. The tag with the most Blabs appears in the top row.

                    Users can advertise within the app. 1,750,000 MYRA(1000 USDT, for 1 week), 3.000.000 MYRA(1750 USDT, for 2 weeks) and 4.250.000 MYRA(2500 USDT, for 3 weeks) must be paid for each advertisement. *MYRA quantity will be updated by adapting to 1000 USDT

                </p>
            </p>
            <iframe className="youtubeVideo" src="https://www.youtube.com/embed/nuAmHF3G5qI">Myra Projects</iframe>

            <div className="nextDiv">
                <Link className="next" to="/projects">Previous</Link>
                <Link className="next" to="/tokenEconomics">Next</Link>
            </div>
        </div>
    )
}
export default Blabber;