import logo from "./images/transparan_myra_logo_official.png"
import ("./Navbar.css")
function Navbar() {
    return(
        <div className="navbarDiv">
            <div className="navbarImgH3">
                <img src={logo} style={{height:"50px",width:"50px"}}/>
                <h3>MYRA</h3>
            </div>
        </div>
    )
}
export default Navbar;