import {Link} from "react-router-dom";
import "../edit/Roadmap.css"
function Roadmap() {
    return(<div>
        <h2 className="roadmapH2">ROADMAP</h2>
        <p className="appP">
            A roadmap is a strategic plan that defines the goal or desired outcome and includes the key steps needed to achieve that goal. A roadmap is a high-level visual outline that helps map out the vision as well as deliver a specific product over a predetermined timeframe. Especially in cryptocurrency projects, it is a plan for executing the strategy of the project and a document that guides its investors. The roadmap contains the key steps needed to achieve the goal or desired outcome.
            Below you can see MYRA's Roadmap:
            In the first stage of the project;
            Smart contract distribution on the Binance Smart Chain (BSC) network,
            Publishing the website,
            Publishing the social media accounts,
            Presale stage,
            Verification of the Smart Contract in the BSC network.
            </p>
            <p className="appP">
            MYRA was created in the 3rd quarter, social media was created. Each step of the 1st stage of our project took place and was completed in the 3rd quarter of 2023.
            In the second stage of the project;
            Publication of the social media platform
            Listing on trending Market apps like Coinmarketcap, Coingecko,
            Advertisements.
            In the third stage of the project;
            Listing on Centralized Exchanges,
            Publishing the New Project,
            Marketing Stage.
            In the future, the decisions and changes made on Roadmap will be announced to our users via our social media accounts. Check out our Github page for more details on Roadmap.
            It should be noted that the Myra team can advance/postpone the dates of applications on Roadmap or change them for the benefit of our users. (According to the process)
            </p>
        


        <div className="nextDiv">
            <Link className="next" to="/tokenEconomics">Previous</Link>
            <Link className="next" to="/team">Next</Link>
        </div>
    </div>)
}
export default Roadmap;