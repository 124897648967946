import {Link} from "react-router-dom";
import "../edit/Team.css"
function Team() {
    return(<div>
        <h2 className="teamH2" >TEAM</h2>
        <p className="appP">
            MYRA is created and developed by our professional team. Our team includes our engineers, software team, finance team, social media team and marketing team. Our team continues its work to contribute to the growth and development of MYRA.
            At the same time, the team continues its research and work to expand the usage areas of the MYRA and strives to create new applications and projects.

        </p>


        <div className="nextDiv">
            <Link className="next" to="/roadmap">Previous</Link>
            <Link className="next" to="/conclusion">Next</Link>
        </div>
    </div>)
}
export default Team;