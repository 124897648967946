import {Link} from "react-router-dom";
import "../edit/Projects.css"
function Projects() {
    return(
        <div className="section3" id="section3">
            <h2 className="projectsH2">PROJECTS</h2>
            <p className="appP">
                MYRA team continues to produce projects in order to expand the usage areas of MYRA. Also, our team continues to develop projects for applications and products that have not yet been made in blockchain technology on the other hand, continues to complete and constantly research projects that are missing in the blockchain world.

               
            </p>
            <iframe className="youtubeVideo" src="https://www.youtube.com/embed/nuAmHF3G5qI">Myra Projects</iframe>

            <div className="nextDiv">
                <Link className="next" to="/myra">Previous</Link>
                <Link className="next" to="/blabber">Next</Link>
            </div>
        </div>
    )
}
export default Projects;