import "./LeftMenu.css"
import {Link} from "react-router-dom"
import {FaBars, FaTimes} from "react-icons/fa";
import {AiOutlineAlignCenter} from "react-icons/ai"
import {useRef} from "react";


function LeftMenu() {
    const navRef = useRef();
    const showNavbar = ()=>{
        navRef.current.classList.toggle("responsive_nav")
    }
    return(<div className="leftMenuDiv">
        <div className="leftMenuList">
            <button className="nav-btn" style={{marginTop:"15px"}} onClick={showNavbar}><FaBars/></button>
            <ul className="leftMenuLu" ref={navRef}>
                <Link className="LeftList" to="/">Introduction</Link>
                <Link className="LeftList" to="/myra">Myra</Link>
                <Link className="LeftList" to="/projects">Projects</Link>
                <Link className="LeftList" to="/blabber">Blabber</Link>
                <Link className="LeftList" to="tokenEconomics">Token Economics</Link>
                <Link className="LeftList" to="/roadmap">Roadmap</Link>
                <Link className="LeftList" to="/team">Team</Link>
                <Link className="LeftList" to="/conclusion">Conclusion</Link>
                <button className="nav-btn nav-close-btn" onClick={showNavbar}><FaTimes/></button>
            </ul>
        </div>
    </div>)
}
export default LeftMenu;