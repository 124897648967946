import {Link} from "react-router-dom";
import "../edit/Conclusion.css"
function Conclusion() {
    return(
        <div className="section5" >
            <h2 className="conclusionH2" >CONCLUSION</h2>
            <p className="appP">
                The projects we offer are on blockchain. With the roadmap we have determined, we will increase the usage areas of MYRA and make it one of the leading tokens in the industry. We will continue to develop products, applications and projects that can be used not only by our investors but also by the whole world.
                Myra continues on the way by taking firm steps and following the road map we have determined. We always work for the best by growing together with her investors.

            </p>


            <div className="nextDiv">
                <Link className="next" to="/team">Previous</Link>
            </div>
        </div>
    )
}
export default Conclusion;