import {Link} from "react-router-dom";
import "../edit/TokenEconomics.css"
function TokenEconomics() {
    return(
        <div>
            <div className="section4" id="section4">
                <h2 className="tokenEconomicsH2" >TOKEN ECONOMICS</h2>
                <p className="appP">
                    Token economics is the combination of "Token" and "economy". It is a concept that covers all the elements and informative texts that make a particular cryptocurrency valuable for investors. This includes everything from the creation of a token to how it is issued and the benefit it provides. Token economics is an important concept to consider before making an investment. This is because a project that has reliable and good steps for buying and holding tokens over the long term is more likely to last longer and perform better than a project that has not built the ecosystem. Our team, which takes existing cryptocurrency projects into account when creating tokens, has finalized the share and token amounts.
                    </p>
                    <p className="appP">Token Name: MYRA</p>
                    <p className="appP">Symbol: MYR</p>
                    <p className="appP">CONTRACT ADDRESS(BSC): 0xef33fd3d46C3EFa1804b513e3dDb7FdFf215DeF1</p>
                    <p className="appP">Total Supply: 10.000.000.000</p>
                    <p className="appP">Decimal: 18</p>

                    <p className="appP">We changed our Smart Contract due to bug. See details : https://t.me/Myra_announcements/371</p>

                    <p className="appP">
                    Reserve constitutes 30% of the project. 3,000,000,000 MYR tokens are reserved for Reserve.
                    Development For Ecosystem constitutes 20% of the project. 2,000,000,000 MYR tokens are reserved for Development For Ecosystem.
                    Marketing constitutes 15% of the project. 1,500,000,000 MYR tokens are reserved for Marketing.
                    Team constitutes 10% of the project. 1,000,000,000 MYR tokens are reserved for Team.
                    Partnership constitutes 14% of the project. 1,400,000,000 MYR tokens are reserved for the Partnership.
                    Corporate Structuring constitutes 5% of the project. 500,000,000 MYR tokens are reserved for Corporate Structuring.
                    </p>
                    <p className="appP">
                    Many cryptocurrency owners distribute free coins to their communities to increase the visibility of their projects, increase the circulating supply, and encourage trading. These sent free cryptocurrencies are commonly known as "airdrops".
                    Many of these airdrops are “bounties” that will reward you with cryptocurrencies for completing social media tasks (like joining a Telegram group, providing likes and follows, resharing posts, etc.). This offer creates a win-win scenario. Because cryptocurrency holders get free marketing and those who complete the task get free cryptocurrency.
                    Community Rewards constitute 5% of the project. 500,000,000 MYR tokens are reserved for Community Rewards.
                    Pre-sale is the sale that takes place before the initial coin offering, where cryptocurrency projects sell their tokens to investors at a certain special or discounted price.
                    If there are no problems and the cryptocurrency is successful, it is beneficial for both the investors and the cryptocurrency's development team. While the owners of the cryptocurrency project provide the funds needed to complete the project, investors purchase an altcoin that may be much more valuable in the future.
                    As MYR, 83,333,333 tokens are reserved for Presale in our project. Our presale price is set at $0.0012. If our investors benefit from presale, it will be delivered to our investors every 30 days at certain rates. Pre-Sale covers 0.83% of the total supply. This percentage has allocated 83,333,333 MYR tokens worth $50,000.
                    Public sale is the final stage of an ICO where a cryptocurrency company offers its token to the public at a significant discount before being listed on centralized or decentralized cryptocurrency exchanges. As the last step of public sale ICO and IDO, it offers its investors a discounted or profitable advantage before going up for sale.
                    Public Sale covers 0.63% of the total supply. In this percentage, 62,500,000 MYR tokens are allocated. Price is $0.0024 on the Public Sale.
                    </p>
               

                <div className="nextDiv">
                    <Link className="next" to="/blabber">Previous</Link>
                    <Link className="next" to="/roadmap">Next</Link>
                </div>
            </div>
        </div>
    )
}
export default TokenEconomics;