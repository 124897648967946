import "./App.css"
import Navbar from "./Navbar";
import Myra from "./pages/Myra";
import {Routes,Route} from "react-router-dom"
import Introduction from "./pages/Introduction";
import Projects from "./pages/Projects";
import TokenEconomics from "./pages/TokenEconomics";
import Roadmap from "./pages/Roadmap";
import Team from "./pages/Team";
import Conclusion from "./pages/Conclusion";
import LeftMenu from "./LeftMenu";
import Blabber from "./pages/Blabber";
function App() {

  return(

      <div>
      <Navbar/>
      <LeftMenu/>
          <div className="pageDiv">
          <Routes>
              <Route path="/" element={<Introduction/>}/>
              <Route path="/myra" element={<Myra/>}/>
              <Route path="/projects" element={<Projects/>}/>
              <Route path="/blabber" element={<Blabber/>}/>
              <Route path="/tokenEconomics" element={<TokenEconomics/>}/>
              <Route path="/roadmap" element={<Roadmap/>}/>
              <Route path="/team" element={<Team/>}/>
              <Route path="/conclusion" element={<Conclusion/>}/>
          </Routes>
          </div>
      </div>

  )
}
export default App;