import {Link} from "react-router-dom";
import "../edit/Myra.css"
function Myra() {
    return(
        <div className="section2">
            <h2 className="myraH2">MYRA</h2>
            <p className="appP">
                <h4>What Is Myra</h4>
                <p>
                    Myra is a structure that aims to provide a unique experience to the users by integrating current technologies into the blockchain. Myra is a token because it belongs to a smart contract. Assets referred to as coins are those that have their own blockchain networks.
                </p>
                <h4>Mission & Vision</h4>
                <p>
                    Our team, who wants to take part in the growing cryptocurrency market and blockchain technology, aims to reach every person who is competent or not in this industry and technology. Our team, which goes on a long-term journey with the products it will develop, aims to grow together with you, our valued users, to become the most important cryptocurrency in the world.
                </p>
            </p>
            <iframe className="youtubeVideo" src="https://www.youtube.com/embed/M87PW1WpKss" >Myra Token></iframe>
            <div className="nextDiv">
                <Link className="next" to="/">Previous</Link>
                <Link className="next" to="/projects">Next</Link>
            </div>
        </div>
    )
}
export default Myra;