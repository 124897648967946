import "../edit/Introduction.css"
import {Link} from "react-router-dom";
function Introduction() {
    return(<div className="section" id="section">
        <h2 className="sectionH2">INTRODUCTION</h2>
        <p className="appP">
            Cryptocurrencies are decentralized digital currencies; therefore, they do not require the intermediation of any financial institution that represents a disruption in the financial system. Cryptocurrencies developed rapidly and became popular assets in global financial markets. It attracted the attention of individual investors and institutional investors. Cryptocurrencies have become an important and current issue.
            With the introduction of cryptocurrencies into our financial world, it did not take long to spread to other areas. Cryptocurrencies have been used in every area. For this reason, we wanted to show that cryptocurrencies can be used in social media applications, games, financial applications and many more. We are here with many projects where we will expand the usage areas of our MYRA. 
            </p>
            <p className="appP">
            This whitepaper contains the information of the MYRA, the information of the project.
            Change, which is indispensable for our age, is mostly seen in technology. Decentralization, which has started to take place in our lives, especially with Blockchain technology, will be indispensable in the future. Blockchain has made itself known with cryptocurrencies such as bitcoin and continues to be developed with cryptocurrencies such as ethereum. At the same time, another area that is indispensable in our lives is social media platforms.
            The use of social media is very common today. By analyzing these situations, our team comes up with a potential project that will both benefit the cryptocurrency world and lead investors. Imagine such a social platform that it will have a structure that will be indispensable for the future (decentralized) and will be managed by a group that speaks the same language as you and understands your problems and wishes. Our team is self-confident and likes to take risks when necessary. We reveal this feature in our projects. This project, which is both unique and pioneering in the crypto world, will provide a great opportunity to highlight our token and our team.
        </p>
        <iframe className="youtubeVideo" src="https://www.youtube.com/embed/aX6cFW08HoI" >Myra Intro></iframe>
        <div className="nextDiv">
        <Link className="next" to="/myra">Next</Link>
        </div>
    </div>)
}
export default Introduction;